import "./Home.css";
import Footer from "../Footer/Footer";
import oilMachine from "../OilMachine.jpeg";
import oil from "../oil.png";
import food from "../food.jpg";
import yogaAgri from "../yogaAgri.jpeg";
import preservatives from "../no-preservatives.png";
import chemicals from "../no-chemical.png";
import adultarents from "../no-adultarents.png";

function Home(){
    return(
        <>
         <div className='home'>
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000">
             
            <div className="carousel-inner">
            <div className="carousel-item active">
                    <img className="d-block" src="https://img.freepik.com/premium-photo/pouring-cooking-oil-golden-background_140916-939.jpg" alt="Sixth slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block" src="https://as2.ftcdn.net/v2/jpg/02/92/12/25/1000_F_292122545_Z5Uvm3PFWs05ojojOfr0O3GzEdsnVodu.jpg" alt="Fourth slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block" src="https://www.asean-agrifood.org/wp-content/uploads/2017/02/Food-quote-8.jpg" alt="Second slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block" src={oil} alt="Fifth slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block" src="https://recipegym.com/wp-content/uploads/2020/03/Food-is-life-quote.jpg" alt="Sixth slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block" src="https://cdn.shopaccino.com/refresh/articles/oil-377690_l.jpg" alt="Fifth slide" />
                </div>
                <div className="carousel-item">
                    <img className="d-block" src="https://m.media-amazon.com/images/I/71nxQGD6dnL._AC_UF350,350_QL80_.jpg" alt="Sixth slide" />
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                 <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
            </div>
            <div className="about">
            <p className="para">
            Our country India is primarily known for its food and varied culture. Spice and oils are some food ingredients without which Indian people cannot think of cooking. According to our geographical factors and topographical features, our ancestors always use cold-pressed oils that contribute to exceptional health and taste. In recent years, due to rumors and misinformation, people have stopped using these oils and switched to refined oils. People are assuming that refined oils will never have any impact on their lifestyle and health. <br/><br/>
            According to a recent study, people know that cold-pressed oils are a healthy substitute for refined oil due to their nutritional benefits. Now before jumping to further conclusions, let us take a detailed look into refined and cold-pressed oils and know how they are different from each other.<br/>
            <b>Refined Oils:</b><br/>
            Seeds and nuts like soybean, sunflower, corn, and many more are chemically processed and treated to get the required oil. They have to go through the refining process as they are obtained through various chemical reactions. This refining process removes the natural benefits and nutritional properties of the oil. These are exceptionally unhealthy to consume, but people tend to incline more towards it due to the lower price.<br/>
            Refined oils are also famous as there is a higher yield. The manufacturing companies heat the oils at high temperatures, increasing and expanding the quantity. Refined oils extracted from the low standard seeds, make the odor, taste, and color of the refined oil neutral. The different varieties of refined oils are present in the market at a reasonable price and include a longer shelf life due to the additives and preservatives presence.<br/>
            <b>Cold-Pressed Oils:</b><br/>
            Cold-pressed oils get extracted without any heat application from the oilseeds like sesame, coconut, sunflower, olive, peanut, and groundnut. Nowadays, companies implement heavy machines to acquire the bulk amount of cold-pressed oils with the help of extreme pressure. These oils not only deliver taste but also give numerous nutrients and control the body’s heat. It also increases our stamina and boosts our immune system. Cold-pressed oils are the best substitutes for refined oil as they give the genuine taste of the nuts or the seeds from which they are extracted and thus deliver the same flavor to the food. <br/><br/>
            <div className="making-process" style={{marginBottom:0}}>
                <h3>Our Farm to Home Process</h3>
            <div className="card mx-auto">
                <div className="card-horizontal">
                <div>
                    <img className="card-img-left" src="https://www.healthkart.com/connect/wp-content/uploads/2022/01/900x500_banner_HK-Connect_Why-is-it-important-to-trim-your-hair_-1.jpg" alt="Card image cap" />
                </div>
                <div className="card-body">
                    <h5 class="card-title">COLLECTING SEEDS</h5>
                    <p className="card-text">We have selected farms from where we collect our seeds. We don't buy them from our regular markets or stores. 
                    We personally choose them for our products as we don't compromise quality. We take utmost care in this process.</p>
                </div>
                </div>
            </div>
            <div className="card mx-auto">
                <div className="card-horizontal">
                <div className="card-body">
                    <h5 class="card-title">PRESSING SEEDS</h5>
                    <p className="card-text">After collecting seeds from farms, we segregate and clean seeds based on quality. 
                    We take the highly sized seeds for our next procedure of pressing. We also don't store seeds for longer durations.</p>
                </div>
                <div>
                    <img className="card-img-left" src="https://previews.123rf.com/images/lestacam/lestacam2306/lestacam230600056/205872424-drying-pressing-extracting-oil-and-cleaning-of-sunflower-seeds-for-the-production-of-sunflower-oil.jpg" alt="Card image cap" />
                </div>
                </div>
            </div>
            <div className="card mx-auto">
                <div className="card-horizontal">
                <div>
                    <img className="card-img-left" src={oilMachine} alt="Card image cap" />
                </div>
                <div className="card-body">
                    <h5 className="card-title">EXTRACTING OIL</h5>
                    <p className="card-text">We start extracting oil from thoroughly cleaned seeds. We don't add any preservatives, chemicals or adultarents
                    in any of our process. We naturally extract oil and move them to packaging.</p>
                </div>
                </div>
            </div>
            <div className="card mx-auto" style={{marginBottom:20}}>
                <div className="card-horizontal">
                <div className="card-body">
                    <h5 class="card-title">PACKAGING</h5>
                    <p className="card-text">Packaging is the place where we stay up with much more hygeine. No hands are involved in packaging. 
                    We have also been much more consicious with our pouch where we tried to make sure, it is of good quality and eco-friendly.</p>
                </div>
                <div>
                    <img className="card-img-left" src={yogaAgri} alt="Card image cap" />
                </div>
                </div>
            </div>
            </div>
            <b>Refined Oil:</b><br/>
            Refined oil is a processed, purified, filtered, and distilled form of the natural oil, which is acquired after treating the oil with several chemicals and preservatives like hexane. The manufacturers also hydrogenate these oils to enhance their shelf life. Through hydrogenation, the companies convert liquid unsaturated fat into a solid fat by putting hydrogen into it. During this process, excess trans-fat gets formed in the oil. <br/>
            This creating and refining process of the refined oils leads to rancid polyunsaturated fatty acids or PUFA formation, which is incapable to hold up high heat. The smell of these oils is so raw that the companies have to deodorize it by adding bleach. <br/><br/>
            <b>How has Refined oil Consumption drastically Increased?</b><br/>
            In the past three decades, the intake of refined oils has increased at the expense of various other fats like butter. This accelerated use of refined oil is primarily due to low prices. These oils are a massive health concern for people due to the high intake of omega-six compounds. According to scientists, too much consumption of omega-six might lead to body inflammation and potentially contributes to various diseases.<br/><br/>
            <b>List of Harmful Things Present in the Refined Oils:</b><br/>
            Below are the lists of some of the toxic things that are present in refined oil.<br/>
            <b>Trans Fat:</b><br/>
            Trans fats are formed when the liquid oil gets hydrogenated at high temperatures. Hydrogenation makes the fat rancid and free-radical, which in turn promotes heart diseases and cancer. Trans fats are also associated with systemic inflammation and weight increase in women.<br/>
            <b>Additives and Preservatives:</b><br/>
            The extensive amount of additives and preservatives that are involved in refined oil processing is horrifying. The oils also contain emulsifiers, artificial flavors, synthetic vitamin forms, colors, and sodium. These substances present in the oil make them difficult to absorb by the human body.<br/>
            Imbalance ratio of Omega 6 and Omega 3:<br/>
            Omega 6 and Omega 3 are significant fats necessary for brain development and health, hormone production, the health of the nervous system, skill and cellular health, and many more. However, you have to consume these fats in proper balance. The correct ratio of omega 3 and 6 are from 1:4 to 1:2. Unfortunately, the refined oils contain a ratio almost closer to 20:1. An excessive amount of omega-six intakes can cause various diseases like cancer, Alzheimer’s, autoimmune diseases, cardiovascular disease, arthritis, and more. <br/><br/>
            <b>Side Effects:</b><br/>
            Now it is time to look into the detail of the refined cooking oil side effects. <br/>
            1. Refined oil can be Cancerous.<br/>
            2. Nickel is one of the most vital chemicals implemented in natural oil processing. This metal is exceptionally harmful to health, and its presence in the cooking oil or food can produce a carcinogenic effect. It also causes adverse effects on the liver, skin, and even on the respiratory system. One of the primary reasons for cancer patient enhancement in tropical countries is refined oil use. <br/>
            3. Refined oils cause problems in the Digestive system.<br/>
            4. Most manufacturing companies add a toxic chemical named sodium hydroxide during the refining process. This adulteration might affect the body’s digestive systems and cause immense trouble in the bowel system. The preservatives added to the oils during the refining process are unhealthy and can also cause various diseases like gastroenteritis, tumors, cysts, and ulcers.<br/>
            5. Cardiac Diseases<br/>
            6. Most people become cautious about the oil used for cooking and their diet whenever they face any cardiac problem. But can you control it unless you select the correct alternative of cooling oil? Well, this is also applicable if you are using refined oils for preparing your daily meals. The high temperatures applied during the refined oil processing remove all the valuable and natural elements from them. Instead, it enhances the amount of trans fat, which is exceptionally harmful to your health and heart. Continuous use of refined oils can act as a passive poison and cause various heart problems like a blockage in the valves or heart attack. <br/>
            7. Various Respiratory Diseases<br/>
            8. The refined oil processing techniques include de-waxing, bleaching, degumming, deodorizing, and various other alkali or acidic treatments. These dangerous techniques decrease the chlorophyll content, smell, flavor, and various other nutrients present in the oil. It also impacts the increased level of oxidation and rancidity in edible oils. The extensive consumption of refined cooking oil has adverse side effects on lung health and makes it tougher to compensate in aging. The lungs’ alveoli slowly get rustier due to the chemicals present in the refined oil. You might experience breathing and many other difficulties due to the reduced blood circulation to the brain.<br/><br/>
            <h3>Replace Refined oils with healthy cold pressed oils.</h3><br/>
            <div className="quality">
                <div className="strategy">
                <h3 style={{color:"rgb(210, 43, 99)"}}>Our Strategy</h3>
                <div className="qualityImages">
                    <div className="preservative"><img src={preservatives}></img><p>No Preservatives</p></div>
                    <div><img src={chemicals}></img><p>No Chemicals</p></div>
                    <div><img src={adultarents}></img><p>No Adultarents</p></div>
                </div>
                </div>
            </div>
            <b>What is meant by Cold-Pressed oil?</b><br/>
            Cold-Pressed oils are nothing but the oil made by grinding the seeds, fruits, vegetables, or nuts into a fine paste. There is no application of heat in the process, keeping all the living enzymes and nutrition intact. These oils contain zero cholesterol and trans fatty acids, which are good for your health. Unrefined and Cold-Pressed oils hold the nutritional value, flavor, and aroma of the seeds or nuts from which they are extracted. <br/><br/>
            Why Refined Oil is Harmful Compared to the Cold Pressed Oils?<br/>
            Refined oils are purified through a procedure, harmful to our health as the manufacturer treats the oils with acid, bleach, or alkali. They also filter, neutralize, and deodorize refined oils by toxic chemicals like hexane, one of the byproducts of crude oils and the petroleum industry. The refined oil also contains polyunsaturated fatty acids, which cannot hold high heats, making it unsuitable for deep frying. The oil oxidizes during cooking and finally turns into trans fat, which is the primary reason for all the heat problems. So should you consume refined oils over the Cold-Pressed oils due to a slight difference in their price? The application of excessive heat might enhance the oil quantity in the extraction process, but it will compromise the product quality. It will also degrade the health and natural benefits of the oil.<br/><br/>
            <b>How are Cold Pressed Oils made?</b><br/>
            From the above segment, you already know what Cold-Pressed oils are and how are they different from refined oil. In this section, we will be sharing the process of making the Cold-Pressed oils below.
            Cold-Pressed oils are obtained by pressing the seeds or fruits through a steel or wooden press. This high pressure will create heat through the friction and extract the oil, discarding the remaining nuts or seeds. The oil will then go through minimum processing and remain enriched with antioxidants, proteins, lecithin, and vitamins. <br/><br/>
            <b>Are Cold Pressed Oils Beneficial for Health?</b><br/>
            Cold-Pressed oils retain the antioxidants damaged in the refined oils due to high heat exposure. Antioxidants help in combating the free radicals that destroy the cells in the body. Most Cold-Pressed oils are full of vitamin E with healing and anti-inflammatory characteristics. These oils also include a high amount of oleic acid that boosts the immune system. According to the researchers, we can use olive, sunflower, coconut, sesame, peanut/groundnut, and canola oil for cooking. With less amount of oil, more varieties of foods can cook easily.<br/><br/>
            <b>Advantage of Cold Pressed Oils:</b><br/>
            The pros of implementing Cold-Pressed oils in your diet are mentioned as follows.<br/>
            1. Full of essential vitamins and antioxidants like phosphaticles and tocopherols.<br/>
            2. Loaded with Vitamin-E.<br/>
            3. Includes anti-inflammatory and antibacterial properties which prevent any infection spread in the body.<br/>
            4. A high Vitamin-C source that penetrates inside the skin and improves the skin health.<br/>
            5. Boost and enhance the power of the immunity system due to oleic acid presence.<br/>
            6. The oils contain a high amount of fatty acids, which is highly significant for health and improves brain function, and has cancer-fighting characteristics.<br/>
            7. Balances the blood pressure levels, thus preventing hypertension or hypotension.<br/>
            8. Free from any bad cholesterol.<br/>
            9. Do not contain any poisonous solvent residues, preservatives, or chemicals.<br/>
            10. Retains the natural odor and flavor that enhancing the taste of the recipes.<br/><br/>
            From the health perspective, there is no doubt Cold-Pressed oils are a clear winner over refined oils. Since these oils include no or minimal refinement, they have become the first preference of most people. These oils also offer a myriad of advantages to the skin, health, and hair. Hopefully, after reading this write-up, you have got all your answer related to what Cold-Pressed oils are and why it is beneficial to switch to them from regular refined oils. <br/><br/>
            <b>Are Cold-Pressed oils Healthy Alternatives to Refined Oils?</b><br/>
            In comparison to refined oils, cold-pressed oils are much less exposed to high heat and are therefore an outstanding option to use in our daily cooking regime. The oils consist of a high amount of Vitamin E, which includes anti-inflammatory and healing properties. Cold-pressed oils are also high sources of antioxidants and oleic acid, helping to enhance the power of the immune system. It also fights the free radicals that destroy the cell in the body, thus preventing cancer. <br/>
            Cold-pressed oils include all the essential nutrients which one will need for the body, like vitamins, lecithin, proteins, and even phospholipids. Due to less exposure to high temperature and heat, it is low in cholesterol that assists in easy weight loss and food digestion. It is also highly nutritious for the skin and enhances its glow. These oils are considered as one of the best refined oil substitutes as they are free from harmful chemicals like Gallate, propyl, BHA, and BHT and are organic. The above chemicals are dangerous for the health and are present as preservatives in refined cooking oils. <br/>
            There are even different chemicals, like bleaching agents, hydroxide, sodium bicarbonate, Hexane, sodium bicarbonate. The most famous cold-pressed oils that act as the top substitutes of refined oil are olive oil, groundnut oil, flaxseed oil, sesame oil, and coconut oil. <br/>
            If someone starts cooking with the cold-pressed oils, they need to be careful with this oil as constant exposure to heat for deep frying or sautéing might break down the unsaturated fat. The process makes it unsafe to use. Some refined oils also offer a false promise of weight loss to the customer by adding various labels on the brand. They use the terms like blanched and lite, which fool the customer as they are processed with chemicals and include no nutritional value. <br/>
            Cold-Pressed oils use high standard seeds, while refined oils use low-quality ones as the company neutralizes them for taste, odor, and color. Refined oils have more shelf life as they do not contain anything organic like the cold-pressed ones. One of the most shocking facts of the refined oil companies is that they sometimes mix cheap oils like palm and cottonseed oils for additional profit. Consumers will not be able to tell the difference between the mixed and the pure ones. <br/>
            Thus using cold-pressed oils is one of the healthy substitutes for refined oil, but you have to make sure that you are not cooking your food by exposing them to higher heat and temperature. You can also add them to your salads and bread to give it an ideal blend of flavor and health. <br/><br/>
            So by now, you have already understood why cold-pressed oils are considered a great substitute for harmful refined oils. Each variety of cold-pressed oils is suitable for specific cooking purposes and health benefits. Though their aroma and taste might differ, one of the most common factors for all of them is that they are all-natural. Thus whichever option of cold-pressed oil you select, you will be getting only all-natural and chemical-free oils, full of all the goodness of that nut or seed. <br/><br/>
            <b>Benefits of Cold Pressed Sunflower Oil:</b><br/>
            Cold-pressed sunflower oil gets extracted from the sunflower plant seeds. It is one of the most popular cooking oils used in the cuisines of Eastern Europe for more than the past ten years. The cold-pressing sunflower oil includes buttery and nutty forms and is used in the vinaigrettes preparation and other lower heat cooking dishes. These oils are high in monounsaturated and polyunsaturated fats, which are essential to maintain a healthy heart. It also includes a mixture of linoleic and oleic acid, which are nothing but Omega 6 and 9. Cold-pressed sunflower oil is pale and light in color with a pleasant aroma and flavor.<br/><br/>
            <b>Nutritional benefits of the sunflower oil:</b><br/>
            One tablespoon of cold-pressed sunflower oil consists of several essential nutrients whose values are as below.<br/>
            <b>Health Benefits of Cold-Pressed Sunflower Oil:</b><br/>
            It is now time to see what the cold-pressed sunflower oil benefits are and how to help in making our health better.<br/>
            1. Sunflower oil is a High Source of Vitamin E.<br/>
            2. Cold-pressing sunflower oil is full of health-boosting nutrients, like Vitamin E, one of the most efficient antioxidants. It assists in protecting the human body from the effects of free radicals. This nutrient helps in building immunity and removes the viral and bacterial infections from the body. The cells execute significant metabolic processes with the help of vitamin E. Cold-pressing sunflower oil decreases the chances of getting any type of cancer like colon by neutralizing the present free radicals responsible for causing cancer. The carotenoids present in the cold-pressed sunflower oil also protect skin, uterine, and lung cancers. <br/>
            3. Act like an Anti Aging agent.<br/>
            4. Are you getting panicked due to the wrinkles and the fine lines that are dominating your face now? Well, worry not, as the cold-pressed sunflower can recreate the skin cells. Due to this, the skin encounters a little damage from the sun’s rays. The benefits of cold-pressed sunflower oil are also efficient on the wounds or the scars that heal much rapidly when you apply sunflower oil on them. It is primarily due to the presence of oleic acid in cold-pressing sunflower oil. That is why you can find the tiny amount of cold-pressing sunflower oil in many well-known beauty products. <br/>
            5. Hair treatment Aid<br/>
            6. Besides being exceptionally beneficial for the skin, the cold-pressed sunflower oil is also helpful as a hair conditioner. It can tame frizzy and dry hair due to the linolenic acid presence that stops any hair loss. <br/>
            7. Great for Heart Health<br/>
            8. There are some specific reasons why the cardiologists recommend the heart patients implement cold-pressing sunflower oil in their cuisines. The high content of Vitamin E and lower amount of unhealthy saturated fats make it perfect to use for heart health. The oil is high in poly and monounsaturated fats, which prevents heart blockages. Cold-pressing sunflower oil consists of different compounds like phenolic acid and choline, which are advantageous for the heart. The phytosterols in sunflower oil are naturally present plant sterols that prevent cholesterol absorption in the human body. According to the Academy of Dietetics and Nutrition study, people should consume 2 grams of phytosterols every day with high cholesterol. It also reduces the risks of cardiovascular diseases. <br/>
            9. Good for the Skin<br/>
            10. Cold-pressing sunflower oil is rich in Vitamin A and E, one of the most intensive antioxidants. The application of the cold-pressing sunflower oil repairs the damaged skin cells moisturizes the sensitive skin and removes the acne. The oil is also used for the therapeutic effect on eczema when you directly apply it to the skin. Vitamin E present in it is specifically efficient against dermatitis also. <br/>
            11. Natural Skin Barrier for the Skin<br/>
            12. The linoleic acid presents in the sunflower act like a natural barrier and assist in retaining the moisture better. It includes additional benefits as an anti-inflammatory agent that works wonders for irritated and dry skin. You can implement any moisturizer or cream, including sunflower oil, as the primary ingredient for moisturizing the body and the face. It is also the best carrier of essential oils. <br/><br/>
            <b>How much is Cold-Pressed Sunflower oil healthy compared to other oils?</b><br/>
            Sunflower oil consists of nearly 13% saturated fat, which is much higher than canola, grapeseed, and safflower oils. But the amount is lower than the palm, cottonseed, coconut soybean, and peanut oil. It includes the same quantity of saturated fat as corn and olive oil. Cold-pressing sunflower oil includes vitamin E and oleic acid and consists of more mono-unsaturated fats than poly-unsaturated ones.<br/><br/>
            <b>Conclusion:</b><br/>
            If you are still thinking, “is refined oil harmful to health?” then you should know that refined oils can cause other diseases apart from the primary ones mentioned above. It might include renal problems, premature aging, emphysema, hypoglycemia, diabetes, various allergies, ulcers, arthritis, and infertility. Thus most nutritionists and physicians recommend picking unrefined and natural cold-pressed oils, which are the best for everyone’s health. <br/><br/>
            </p>
            </div>
            <div className="container">
            <img src="https://img.freepik.com/free-photo/sunflower-oil-plastic-bottle-wooden-table_123827-21121.jpg"></img>
            <div className="top-right">Your<br/> Health<br/> Our<br/> Concern</div>
            </div>
            <img></img>
         </div>
        </>
    )
}

export default Home;