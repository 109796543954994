import "./Product.css";
import image1 from "../imageGrid1.jpg";
import image2 from "../imageGrid.jpg";

function Product(){
    const values = [
        {
            "parameter": "Vitamin A",
            "UOM" : "IU/100mg",
            "Result": "<33.33"
        },
        {
            "parameter": "Cholestrol",
            "UOM" : "mg/100g",
            "Result": "0.0"
        },
        {
            "parameter": "Energy/Calories(by calc)",
            "UOM" : "KCal/100g",
            "Result": "897"
        },
        {
            "parameter": "Carbohydrates(by calc)",
            "UOM" : "g/100mg",
            "Result": "0.0"
        },
        {
            "parameter": "Total Fat",
            "UOM" : "g/100g",
            "Result": "99.70"
        },
        {
            "parameter": "Vitamin D3",
            "UOM" : "IU/100g",
            "Result": "1426.34"
        },
        {
            "parameter": "Saturated Fatty Acids",
            "UOM" : "g/100g",
            "Result": "18.93"
        },
        {
            "parameter": "Protein",
            "UOM" : "g/100g",
            "Result": "<0.1"
        },
    ]
    return (
        <>
         <div className="product">
         <div className="row">
            <img src={image2}></img>
            <img src="https://img2.exportersindia.com/product_images/bc-full/2020/3/6066065/iii-555--1584343773.jpg"></img>
            <img src="https://www.happybetterwiser.com/wp-content/uploads/2015/01/healthy-eating-quotes-6.jpg"></img>
            <img src="https://previews.123rf.com/images/belchonock/belchonock1711/belchonock171102383/90582081-bottle-of-cooking-oil-and-sunflower-on-background.jpg"></img>
            <img src="https://i.pinimg.com/736x/4f/26/74/4f267466cebf25b2d51a0b9a202b3348.jpg"></img>
         </div>
            <p>
                Yoga sunflower oil emerges as a culinary essential, embodying a commitment to purity, health, and a touch of nature in every single drop.
                Crafted with meticulous attention, this sunflower oil distinguishes itself in the market through its exceptional quality and a distinctive production process.<br/>
                Exclusively utilizing white sunflower seeds, Yoga Sunflower Oil proudly excludes black seeds, ensuring an unparalleled standard of quality. What truly sets it apart is
                the zero-hand-touch approach, assuring purity from manufacturing to the consumer's kitchen. With a precise balance of 30% wax, the oil offers a harmonious
                blend of health benefits and culinary excellence.<br/>
                <ul>
                    <li><b>High-Quality Ingredients:</b><br/> We use pure white sunflower seeds which are collected directly from farms with utmost care and quality.
                        We don't collect seeds from local stores or markets. We also don't store seeds for longer duration where we believe it may decrease the quality of our product. 
                        No human touch is involved during processing, and we do use a wax removal process for a light, healthy oil.</li>
                    <li><b>Distinct Health Benefits:</b><br/> We generally buy oils from local stores but totally unaware of the health benefits or issues it may cause. 
                    We also don't bother about the cholestrol levels and other nutrients. Here we are sharing you our real laboratory data for our oil and health benefits:
                    <table>
                        <thead>
                            <th>Parameters</th>
                            <th>UOM</th>
                            <th>Results</th>
                        </thead>
                        <tbody>
                            {
                                values.map(it => (
                                    <tr>
                                       <td>{it.parameter}</td>
                                       <td>{it.UOM}</td>
                                       <td>{it.Result}</td> 
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    </li>
                    <li><b>Eco-Friendly Packaging:</b><br/> As our primary focus is health in all aspects, in packaging we tried to use eco-friendly and biodegradable pouches.</li>
                    <li><b>Future Tetra Pack Plans:</b><br/> The future enhancement of our yoga agri foods is gonna be tetra packs. 
                    We are planning to make our oil available in tetra packs soon which improves and promotes our health at much more beneficiary levels.</li>
                    <li><b>Product Line Expansion:</b><br/> As part of future expansion, we are planning to introduce <b>yoga ghee</b> with utmost purity and quality.</li>
                </ul>
            </p>
         </div>
        </>
    )
}

export default Product;