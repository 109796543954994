import React from "react";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Footer from './Footer/Footer';
import Home from './Home/Home';
import { BrowserRouter as Router, Routes, Route, Link, HashRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import yogaAgri from "./yogaAgri.jpeg";
import About from "./About/About";
import Product from "./Products/Product";

function App() {
  return (
    <div className="App">
    <Router>
      <Helmet>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16"></link>
        <title>YogaAgri</title>
      </Helmet>
      <div className='header'>
        <nav className="navbar navbar-expand-lg">
          <div class="navbar-brand">
            <div><img src={yogaAgri} alt="" /></div>
            <div><span className="brand-name">YOGA AGRI FOODS<small>Purity at your hands</small></span></div>
          </div>
          
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className='fa fa-navicon' style={{padding:2,color:"black"}}></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#" style={{marginLeft:0}}><Link to={`/`}>HOME</Link></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" style={{marginLeft:0}}><Link to={`/Product`}>PRODUCTS</Link></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" style={{marginLeft:0}}><Link to={`/About`}>ABOUT US</Link></a>
              </li>
           </ul>
         </div>
        </nav>
      </div>
      <div className="line">.</div>
      
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path="/About" element={<About/>}></Route>
        <Route path="/Product" element={<Product/>}></Route>
      </Routes>
    </Router>
    <Footer />
    </div>
  );
}

export default App;
