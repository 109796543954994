import React from 'react';
import "./footer.css";

class Footer extends React.Component{
  render(){
    return(
        <>
         
      <footer className="footer-distributed">
      <p><a href="http://www.facebook.com/chep.iisc" target="_blank"><i className="fa fa-facebook fa-lg"></i></a>
      <a href="https://www.linkedin.com/school/indian-institute-of-science/?originalSubdomain=in" target="_blank"><i className='fa fa-linkedin fa-lg'></i></a>
      <a href="https://twitter.com/iiscbangalore" target="_blank"><i className='fa fa-twitter fa-lg'></i></a>
      <a href="https://twitter.com/iiscbangalore" target="_blank"><i className='fa fa-instagram fa-lg'></i></a>
      <a href="mailto:saibhavana999@gmail.com" target="_blank"><i className='fa fa-envelope fa-lg'></i></a></p>
      <p><i className="fa fa-phone"></i> +91 9666966549, 9160494349<br/>
      <span><i className="fa fa-address-card"></i> Plot No.278, SyNo.746-2(Part), Rapthadu, Anantapur, AndhraPradesh, 515722.</span></p>
      <p id="p1"><i className='fa fa-copyright'></i> 2023 Yoga Agri Foods, Rapthadu, AndhraPradesh, India. All Rights Reserved<br/>
      <span style={{fontSize:9}}>Designed by : S.E. SAI BHAVANA (saibhavana999@gmail.com)(+91-6300721735)</span></p>
		</footer>
        </>
    )
  }
}

export default Footer;
