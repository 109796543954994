import "./About.css";
import lakshmi from "../lakshmi.jpg";
import chenna from "../chenna.jpg";

function About() {
    return(
        <>
        <div className="about">
         <div className="card mx-auto">
                <div className="card-horizontal">
                <div>
                    <img className="card-img-left" src={lakshmi} alt="Card image cap" />
                </div>
                <div className="card-body">
                    <h3 class="card-title">MANAGING DIRECTOR</h3>
                    <h5 className="name">SREELAKSHMI RAMAGOWNI</h5>
                    <p className="card-text">"Basically I am science graduate, having passion of providing high quality product to protect total family. As for every home and every house wife family is everything.
             Our Yoga Sunflower kernel Oil emerges as a culinary essential, embodying a commitment to purity, health, and a touch of nature in every single drop. Crafted with meticulous attention, this sunflower kernel oil distinguishes itself in the market through its exceptional quality and a distinctive production process.
             Exclusively utilizing white kernels of sunflower seeds, Yoga Sunflower Oil proudly excludes black outer shell, ensuring an unparalleled standard of quality. What truly sets it apart is the zero-hand-touch approach, assuring purity from manufacturing to the consumer's kitchen.
             The Oil is more than Gold or Diamond for every family in terms of health protection. Used perfected the technique to extract oil from the seeds without the use of chemicals or heat treatment. Using patented technology to extract the oil at room temperature so that all the micronutrients of the oils are intact. 
             Our aim is to promote a healthy lifestyle by ensuring that our consumers understand the benefits of consuming unadulterated edible oils." <br/><span style={{float:"right"}}><b>- SreeLakshmi</b></span></p>
                </div>
                </div>
            </div>
            <div className="card mx-auto">
                <div className="card-horizontal">
                <div>
                    <img className="card-img-left" src={chenna} alt="Card image cap" />
                </div>
                <div className="card-body">
                    <h3 class="card-title">CEO</h3>
                    <h5 className="name">RAJESH RAMAGOWNI</h5>
                    <p className="card-text">"Basically I am Masters in Microbiology, having 20 years of high quality experience in the Food and Pharma Industry. 
                Having great experience in all the areas right from Facility Design, projects, production, warehouse, quality control, quality assurance and Regulatory requirement. 
                Keeping all these aspects in mind, the facility is designed by using unidirectional flow to avoid cross contamination of materials and to have appropriate personnel movement.
                Adopted science and technology process in Sunflower kernel oil manufacturing process. This process technology is applied for Patent. 
                The process is designed with a fully automatic process and procures high quality equipment without any compromise. 
                Our Yoga Sunflower kernel Oil is basically made from sunflower seeds by removing the outer shell of the seeds. 
                We use inner seeds (In English they are called as White Kernels and In Telugu they are called White Pappu) to produce the oil at room temperature by using a fully automatic process. 
                Every step of the process and facility is designed by taking at most care to produce this Oil under a closed Environment with appropriate practices."<br/><span style={{float:"right"}}><b>- Rajesh</b></span></p>
                </div>
                </div>
            </div>
            <img></img>
        </div>
        </>
    )
}

export default About;